<template>
	<ValidationObserver ref="form">
		<form id="login" method="POST" @submit.prevent="doLogin">

			

		</form>
		<router-link :to="{ name: 'login'}">
			Si recuerdo mi contraseña
		</router-link>
	</ValidationObserver>
</template>

<script>
export default {
	name: "ForgetPassword",
};
</script>

<style lang="scss" scoped></style>
